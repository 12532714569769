const apiPrefix = process.env.REACT_APP_API;

const appConfig = {
  apiPrefix,
  authenticatedEntryPath: "/home",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  enableMock: false,
  apiVersion: "v1",
};

export default appConfig;
